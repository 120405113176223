/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Flex, Modal, Space, Tabs, TabsProps } from "antd";
import { ColumnsType } from "antd/es/table";
import React, { useEffect, useState } from "react";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { IUserSelectorType } from "interfaces";
import { getProductData } from "redux/actions";
import "./index.css";

import { PlusOutlined, CloudUploadOutlined } from "@ant-design/icons";

import { deleteProductData } from "redux/actions/shop/deleteProduct";

import AllProducts from "./AllProducts";
import UpdateProduct from "./UpdateProduct/index";
import NewProduct from "./NewProduct";
import UploadProductFile from "./UploadProductFile";
import SuppliersList from "./Suppliers";
import NewSupplier from "./NewSupplier";

const userSelector: TypedUseSelectorHook<IUserSelectorType> = useSelector;

interface DataType {
  id: string;
  price: string;
  picture: string;
  article: string;
  quantity: string;
  currency: string;
  unit_price: string;
  item: string;
  name: string;
}

const ProductTable = () => {
  const dispatch = useDispatch();
  const [productData, setProductData] = useState<any>([]);
  const [openUpdateProductModal, setUpdateProductModal] =
    useState<boolean>(false);
  const [openDeleteProductModal, setOpenDeleteProductModal] =
    useState<boolean>(false);
  const [openAddProductModal, setOpenAddProductModal] =
    useState<boolean>(false);
  const [openAddSupplierModal, setOpenAddSupplierModal] =
    useState<boolean>(false);
  const [openUploadProductModal, setOpenUploadProductModal] =
    useState<boolean>(false);
  const [dataToUpdate, setDataToUpdate] = useState<any>();
  const [itemToDelete, setItemToDelete] = useState<string | number>("");
  const { getProduct, getUserData } = userSelector((user) => user);

  const userData =
    getUserData?.data?.data !== undefined ? getUserData?.data?.data[0] : [];

  const onHandleOpenUpdateProduct = (data: any) => {
    setUpdateProductModal(true);
    setDataToUpdate(data);
  };
  const onHandleCloseUpdateProduct = () => {
    setUpdateProductModal(false);
  };
  const onHandleCloseDeleteProduct = () =>
    setOpenDeleteProductModal(!openDeleteProductModal);
  const onHandleOpenDeleteProduct = (data: any) => {
    setOpenDeleteProductModal(true);
    setItemToDelete(data?.id);
  };
  const onDeleteProduct = () => removeItem(itemToDelete);
  const onHandleOpenAddProduct = () => setOpenAddProductModal(true);
  const onHandleCloseAddProduct = () => setOpenAddProductModal(false);
  const onHandleOpenUploadProduct = () => setOpenUploadProductModal(true);
  const onHandleCloseUploadProduct = () => setOpenUploadProductModal(false);
  const onHandleOpenAddSupplier = () => setOpenAddSupplierModal(true);
  const onHandleCloseAddSupplier = () => setOpenAddSupplierModal(false);

  const columns: ColumnsType<DataType> = [
    {
      title: "Name",
      dataIndex: "article",
      key: "article",
    },
    {
      title: "Unite price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
    },
    {
      title: "Action",
      key: "action",
      width: "200px",
      render: (_, data) => (
        <Space size="middle">
          {userData?.role_id == 5 ? (
            <Button
              ghost
              type="primary"
              onClick={() => onHandleOpenUpdateProduct(data)}
            >
              Update
            </Button>
          ) : null}

          <Button
            disabled
            onClick={() => onHandleOpenDeleteProduct(data)}
            danger
            ghost
            type="primary"
          >
            Delete
          </Button>
        </Space>
      ),
    },
  ];

  const data: DataType[] = productData?.map((item: DataType) => {
    return {
      id: item?.id,
      price: `${item?.unit_price}`,
      picture: item?.picture,
      article: item?.item,
      quantity: item?.quantity,
      supplier: item?.name,
    };
  });

  const removeItem = (productId: any) => {
    const userData: any = { id: productId };
    dispatch(deleteProductData(userData));
  };

  useEffect(() => {
    dispatch(getProductData());
  }, [dispatch]);

  useEffect(() => {
    if (getProduct?.data?.data) {
      setProductData(getProduct?.data?.data);
    }
  }, [getProduct?.data]);

  const OperationSlot = (
    <Flex justify="flex-end">
      {userData?.role_id == 5 ? (
        <Button
          ghost
          type="primary"
          icon={<PlusOutlined />}
          onClick={onHandleOpenAddSupplier}
        >
          Supplier
        </Button>
      ) : null}

      {userData?.role_id === 4 ? (
        <Flex gap="middle">
          <Button
            icon={<PlusOutlined />}
            ghost
            type="primary"
            onClick={onHandleOpenAddProduct}
          >
            Product
          </Button>
          <Button
            icon={<CloudUploadOutlined />}
            ghost
            type="primary"
            onClick={onHandleOpenUploadProduct}
          >
            Upload Products
          </Button>
        </Flex>
      ) : null}
    </Flex>
  );

  const tabItems: TabsProps["items"] = [
    {
      label: "Shop Products",
      key: "1",
      children: (
        <AllProducts
          data={data}
          columns={columns}
          loadingData={getProduct?.loading}
        />
      ),
    },
    {
      label: "Suppliers",
      key: "2",
      children: <SuppliersList />,
    },
  ];

  return (
    <>
      <Flex
        align="center"
        justify="space-between"
        style={{ width: "100%", padding: "10px" }}
      >
        <Tabs
          style={{ width: "100%" }}
          defaultActiveKey="1"
          tabBarExtraContent={OperationSlot}
          items={tabItems}
        />
      </Flex>
      <UpdateProduct
        dataToUpdate={dataToUpdate}
        open={openUpdateProductModal}
        onClose={onHandleCloseUpdateProduct}
      />
      <UploadProductFile
        open={openUploadProductModal}
        onClose={onHandleCloseUploadProduct}
      />
      <NewProduct
        open={openAddProductModal}
        onClose={onHandleCloseAddProduct}
      />
      <NewSupplier
        open={openAddSupplierModal}
        onClose={onHandleCloseAddSupplier}
      />

      <Modal
        title="Remove Product"
        open={openDeleteProductModal}
        onCancel={onHandleCloseDeleteProduct}
        footer={
          <Flex justify="space-between">
            <Button
              type="primary"
              onClick={onHandleCloseDeleteProduct}
              ghost
              danger
            >
              Cancel
            </Button>
            <Button type="primary" onClick={onDeleteProduct} ghost>
              Yes
            </Button>
          </Flex>
        }
      >
        <span>Are you sure you want to remove this product item?</span>
      </Modal>
    </>
  );
};

export default ProductTable;
